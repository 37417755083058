<template>
	<div style="width: 100%; height: 100%;">
		<AgendaMoreEventsDialog v-if="moreEventDialogItem" :day="moreEventDialogItem" :style="moreEventDialogItemStyle" ref="moreEventsContainer" @close="moreEventDialogItem = null" />
		<table @wheel="handleScroll($event)" :class="{animateRight: getAnimate('right'), animateLeft: getAnimate('left'), hideWeekends: !blAgendaOptions().showWeekends}">
			<thead>
				<tr>
					<th class="weekNumber" v-if="blAgendaOptions().showWeekNumbers"></th>
					<th v-for="day in weekDays" :key="day">{{ day }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(week, mIndex) in data" :key="mIndex">
					<td class="weekNumber" v-if="blAgendaOptions().showWeekNumbers">{{ week[0].week }}</td>
					<td v-for="(day, dIndex) in week" :key="dIndex" :class="{disabled: day.disabled}" @click="blAgendaEventForm(day.date)">
						<div class="titleContainer"><h4 :class="{today: day.today}">{{ day.day }}</h4></div>
						<div class="agendaViewMonthEvents">
							<div
								v-for="event in day.events"
								:key="event.id"
								:style="{width: eventDisplayOptions[event.id]?.width, marginTop: eventDisplayOptions[event.id]?.top, backgroundColor: event.wholeDay ? event.color : null, display: eventDisplayOptions[event.id]?.hide ? 'none' : null}"
								:class="{timedEvent: !event.wholeDay, startBefore: event.startBefore, endAfter: event.endAfter, previewed: event.previewed}"
								@click="$event.stopPropagation(); blAgendaPreviewEvent(event, $event.target)"
								:id="'blAgendaEvent_' + event.realId"
								bl-agenda-event
							>
								<span v-if="!event.wholeDay" :style="{backgroundColor: event.color}"></span>
								<em v-if="event.startTime">{{ event.startTime }}</em>
								<div>{{ event.title }}</div>
								<BlProfilePicture class="blProfilePicture" v-if="event.owner.id != currentUserId" size="14" :id="event.owner.id" :showStatus="false" />
							</div>
							<div class="showMore" @click="$event.stopPropagation(); moreEventsDialog(day, $event)" :style="{marginTop: showMoreEvents[day.index].top, width: showMoreEvents[day.index].width}" v-if="showMoreEvents[day.index]" :id="'blAgendaEventShowMore_' + day.uid">
								{{ showMoreEvents[day.index].count }} {{ $t(showMoreEvents[day.index].count == 1 ? 'more event' : 'more events') }}
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { DateFormat, ViewServices } from 'InterfaceBundle'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)
import isoWeek from 'dayjs/plugin/isoWeek'
dayjs.extend(isoWeek)
import { AgendaGrid } from 'AgendaBundle'
import AgendaMoreEventsDialog from './AgendaMoreEventsDialog'

export default {
	name: 'AgendaMonthView',
	components: {
		AgendaMoreEventsDialog
	},
	data() {
		return {
			data: [],
			dataByDay: {},
			animate: null,
			eventDisplayOptions: {},
			showMoreEvents: {},
			weekDays: {},
			moreEventDialogItemStyle: {},
			moreEventDialogItem: null,
			currentUserId: ViewServices.interfaceData.userPreferences.id
		}
	},
	created() {
		this.weekDays = JSON.parse(JSON.stringify(this.blAgendaPeriod.allDays))
		if(!this.blAgendaOptions().showWeekends) {
			delete this.weekDays[5]
			delete this.weekDays[6]
			this.weekDays = Object.values(this.weekDays)
		}
	},
	mounted() {
		this.resizeSub = this.blAgendaSizeChange.subscribe(this.setEventDimensions)
		this.periodChangeSub = this.blAgendaPeriod.change.subscribe(way => this.handlePeriodChange(way))
		this.eventsChangeSub = this.blAgendaEventsChange.subscribe(() => this.handleEventsChange())
	},
	unmounted() {
		this.periodChangeSub.unsubscribe()
		this.resizeSub.unsubscribe()
		this.eventsChangeSub.unsubscribe()
	},
	inject: ['blAgendaEventsChange', 'blAgendaChangePeriod', 'blAgendaPeriod', 'blAgendaEvents', 'blAgendaSizeChange', 'blAgendaOptions', 'blAgendaPreviewEvent', 'blAgendaEventForm', 'blAgendaClosePreview', 'blAgendaEventDayUid'],
	methods: {
		setEventDimensions() {
			const cellWidth = (this.$el.getBoundingClientRect().width - (this.blAgendaOptions().showWeekNumbers ? 29 : 1)) / this.weekDays.length
			const cellHeight = ((this.$el.getBoundingClientRect().height - 25) / this.data.length) - 55
			const ret = AgendaGrid.month(this.blAgendaEvents(), cellWidth, cellHeight)
			this.eventDisplayOptions = ret.eventDisplayOptions
			this.showMoreEvents = ret.showMoreEvents
		},
		getAnimate(type) {
			return this.animate == type
		},
		generateDataTable() {
			this.dataByDay = {}
			const currentMonth = this.blAgendaPeriod.date
			const today = (new Date()).toDateString()
			const start = new Date(this.blAgendaPeriod.start)
			const end = this.blAgendaPeriod.end
			const showWK = this.blAgendaOptions().showWeekends
			let data = []
			while(start <= end) {
				if(DateFormat.getLocalDay(start) == 0) data.push([])
				if(!showWK && [6, 0].includes(start.getDay())) {
					start.setDate(start.getDate() + 1)
					continue
				}
				let line = {
					index: start.toDateString(),
					uid: this.blAgendaEventDayUid(start),
					date: new Date(start),
					today: start.toDateString() == today,
					week: dayjs(new Date(start)).format('W'),
					disabled: start.getMonth() != currentMonth.getMonth(),
					day: start.getDate(),
					events: []
				}
				this.dataByDay[line.index] = line
				data[data.length - 1].push(line)
				start.setDate(start.getDate() + 1)
			}
			this.data = data
		},
		handleScroll(event) {
			event.stopPropagation()
			event.preventDefault()
			if(!this.scrollTimeout) {
				if(event.deltaY > 20) this.blAgendaChangePeriod(1)
				else if(event.deltaY < -20) this.blAgendaChangePeriod(-1)
				this.scrollTimeout = setTimeout(() => this.scrollTimeout = null, 100)
			}
		},
		handlePeriodChange(way) {
			this.moreEventDialogItem = null
			this.generateDataTable()
			if(way) this.animate = way == -1 ? 'left' : 'right'
			if(this.animationTimeout) clearTimeout(this.animationTimeout)
			this.animationTimeout = setTimeout(() => this.animate = null, 200)
		},
		handleEventsChange() {
			for(let event of this.blAgendaEvents()) {
				if(this.dataByDay[event.start.toDateString()]) this.dataByDay[event.start.toDateString()].events.push(event)
			}
			this.setEventDimensions()
		},
		moreEventsDialog(day, event) {
			this.blAgendaClosePreview()
			const containerBox = event.target.parentElement.parentElement.getBoundingClientRect()
			this.moreEventDialogItem = day
			this.moreEventDialogItemStyle = {
				width: containerBox.width + 'px',
				left: (containerBox.left - 10) + 'px',
				top: (containerBox.top + 20) + 'px'
			}
			this.$nextTick(() => {
				const contentBox = this.$refs.moreEventsContainer.$el.getBoundingClientRect()
				const monthContainerBox = this.$el.getBoundingClientRect()
				if(contentBox.bottom > monthContainerBox.bottom) {
					delete this.moreEventDialogItemStyle.top
					this.moreEventDialogItemStyle.top = (monthContainerBox.bottom - contentBox.height) + 'px'
				}
			})
		}
	}
}
</script>

<style scoped lang="scss">
	table {
		position: relative;
		margin-left: -1px;
		width: calc(100% + 2px);
		height: calc(100% + 1px);
		border-collapse: collapse;
		flex: 1;

		tr :is(td, th):not(.weekNumber) {
			width: calc(100% / 7);
		}

		tr :is(td, th).weekNumber {
			background-color: red;
			padding: 6px;
			font-family: 'Product sans';
			text-align: center;
			font-weight: 600;
			background-color: var(--bl-background);
			color: var(--bl-legend);
			border-right: 0;
			font-size: 13px;
		}

		tr:last-child td {
			border-bottom: 0;
		}

		tr :is(td, th).weekNumber + :not(.weekNumber) {
			border-left: 0;
		}

		thead tr {
			height: 1px;

			th {
				font-family: 'Product sans';
				text-align: center;
				font-weight: 600;
				color: var(--bl-legend);
				font-size: 11px;
				padding: 4px;
				border-right: 1px solid var(--bl-border);
				border-left: 1px solid var(--bl-border);
				text-transform: uppercase;
			}
		}

		tbody tr:first-child td {
			border-top: none;
		}

		tbody tr {
			height: calc(100% / 6);
			min-height: calc(100% / 6);

			td {
				vertical-align: top;
				border: 1px solid var(--bl-border);

				.titleContainer {
					display: flex;
					justify-content: center;

					h4 {
						font-weight: normal;
						margin: 0;
						padding: 6px;
						text-align: center;
						font-size: 12px;
						display: inline-block;
					}

					h4.today {
						border-radius: 50%;
						color: var(--bl-on-secondary);
						background-color: var(--bl-secondary);
						min-width: 14px;
					}
				}
			}

			td.disabled .titleContainer {
				opacity: .4;
			}
		}
	}

	.animateRight {
		animation: bl-calendar-animateright .2s;
	}

	@keyframes bl-calendar-animateright {
		0% {
			transform: translateX(40px);
		}
		100% {
			transform: translateX(0);
		}
	}

	.animateLeft {
		animation: bl-calendar-animateleft .2s;
	}

	@keyframes bl-calendar-animateleft {
		0% {
			transform: translateX(-40px);
		}
		100% {
			transform: translateX(0);
		}
	}

	table.hideWeekends tr :is(td, th):not(.weekNumber) {
			width: calc(100% / 5);
	}

	.agendaViewMonthEvents {
		margin-top: 4px;

		> div {
			user-select: none;

			> * {
				pointer-events: none;
			}
		}
	}
</style>