<template>
	<div class="agendaViewMonthEvents bl-light-scroll bl-card shadow">
		<div
			v-for="event in day.events"
			:key="event.id"
			:style="{backgroundColor: event.wholeDay ? event.color : null}"
			:class="{timedEvent: !event.wholeDay, startBefore: event.startBefore, endAfter: event.endAfter, previewed: event.moreEventsPreviewed}"
			@click="$event.stopPropagation(); blAgendaPreviewEvent(event, $event.target, true)"
			bl-agenda-event
			:id="'blAgendaEventMore_' + event.realId"
		>
			<span v-if="!event.wholeDay" :style="{backgroundColor: event.color}"></span>
			<em v-if="event.startTime">{{ event.startTime }}</em>
			<div>{{ event.title }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AgendaMoreEventsDialog',
	inject: ['blAgendaPreviewEvent'],
	props: ['day'],
	emits: ['close'],
	mounted() {
		this.$nextTick(() => {
			this.handleBodyClickMoreEventsCb = e => this.handleBodyClickMoreEvents(e)
			document.addEventListener('mousedown', this.handleBodyClickMoreEventsCb)
		})
	},
	unmounted() {
		if(this.handleBodyClickMoreEventsCb) document.removeEventListener('mousedown', this.handleBodyClickMoreEventsCb)
	},
	methods: {
		handleBodyClickMoreEvents(ev) {
			if(!this.$el.contains(ev.target)) this.$emit('close')
		}
	}
}
</script>

<style scoped lang="scss">
	.agendaViewMonthEvents {
		position: absolute;
		z-index: 3;
		max-height: 250px;
		overflow-x: auto;

		> div {
			position: relative;
			margin-top: 2px;

			> * {
				pointer-events: none;
			}
		}
	}
</style>