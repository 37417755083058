<template>
	<div style="height: 100%; width: 100%; display: flex; flex-direction: column;">
		<AgendaMoreEventsDialog v-if="moreEventDialogItem" :day="moreEventDialogItem" :style="moreEventDialogItemStyle" @close="moreEventDialogItem = null" />
		<table :style="{width: 'calc(100% - ' + scrollbarWidth + 'px)'}" style="border-bottom: 1px solid var(--bl-border);">
			<thead>
				<tr>
					<th class="hourLabel"><div>00:00</div></th>
					<th class="dayHeader" v-for="day in data" :key="day.index" :class="{today: day.today}" :style="{width: 'calc(100% / ' + data.length + ')'}">
						<div @click="blAgendaEventForm(day.date)">
							<em>{{ day.dayName }}</em>
							<h1>{{ day.day }}</h1>
						</div>
						<div class="hideBorder"></div>
						<div class="agendaViewMonthEvents" :style="{height: allDayEventsHeight + 'px'}">
							<div
								v-for="event in day.events"
								:key="event.id"
								:style="{width: eventDisplayOptions[event.id]?.width, marginTop: eventDisplayOptions[event.id]?.top, backgroundColor: event.wholeDay ? event.color : null, display: eventDisplayOptions[event.id]?.hide ? 'none' : null}"
								:class="{timedEvent: !event.wholeDay, startBefore: event.startBefore, endAfter: event.endAfter, previewed: event.previewed}"
								@click="blAgendaPreviewEvent(event, $event.target)"
								:id="'blAgendaEvent_' + event.realId"
								bl-agenda-event
							>
								<span v-if="!event.wholeDay" :style="{backgroundColor: event.color}"></span>
								<em v-if="event.startTime">{{ event.startTime }}</em>
								<div>{{ event.title }}</div>
								<BlProfilePicture class="blProfilePicture" v-if="event.owner.id != currentUserId" size="14" :id="event.owner.id" :showStatus="false" />
							</div>
							<div class="showMore" @click="moreEventsDialog(day, $event)" :style="{marginTop: showMoreEvents[day.index].top, width: showMoreEvents[day.index].width}" v-if="showMoreEvents[day.index]" :id="'blAgendaEventShowMore_' + day.uid">
								{{ showMoreEvents[day.index].count }} {{ $t(showMoreEvents[day.index].count == 1 ? 'more event' : 'more events') }}
							</div>
						</div>
					</th>
				</tr>
			</thead>
		</table>
		<div style="overflow: auto; margin-bottom: 49px;" ref="hourTableContainer">
			<table>
				<tbody>
					<tr v-for="hour in hours" :key="hour.index">
						<th class="hourLabel">
							<div v-if="hour.index != 0">{{ hour.label }}</div>
						</th>
						<td v-for="day in data" :key="day.index" :style="{width: 'calc(100% / ' + data.length + ')'}" @click="blAgendaEventForm(day.date, null, hour.label)">
							<template v-if="hour.index == 0">
								<div v-if="day.today" :style="{top: hourIndicatorHeight + 'px'}" class="hourIndicator"></div>
								<div
									class="hourEvent"
									v-for="event in day.hourEvents"
									:key="event.id"
									:class="{previewed: event.previewed}"
									:style="{width: eventDisplayOptions[event.id]?.width, maxWidth: eventDisplayOptions[event.id]?.width, top: eventDisplayOptions[event.id]?.top, backgroundColor: event.color, height: eventDisplayOptions[event.id]?.height, left: eventDisplayOptions[event.id]?.offset}"
									@click="$event.stopPropagation(); blAgendaPreviewEvent(event, $event.target)"
									bl-agenda-event
								>
									<div>{{ event.title }}</div>
									<BlProfilePicture class="blProfilePicture" v-if="event.owner.id != currentUserId" size="14" :id="event.owner.id" :showStatus="false" />
								</div>
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { DateFormat, ViewServices } from 'InterfaceBundle'
import { AgendaGrid } from 'AgendaBundle'
import AgendaMoreEventsDialog from './AgendaMoreEventsDialog'

export default {
	name: 'AgendaWeekView',
	components: {
		AgendaMoreEventsDialog
	},
	data() {
		return {
			data: [],
			hours: [],
			eventDisplayOptions: {},
			showMoreEvents: {},
			allDayEventsHeight: 0,
			scrollbarWidth: 18,
			hourEvents: {},
			hourIndicatorHeight: 300,
			moreEventDialogItemStyle: {},
			moreEventDialogItem: null,
			currentUserId: ViewServices.interfaceData.userPreferences.id
		}
	},
	created() {
		this.hours = []
		for(let i = 0; i < 24; i++) {
			const label = (i < 10 ? '0' : '') + i + ':00'
			this.hours.push({index: i, label: label})
		}
	},
	mounted() {
		this.resizeSub = this.blAgendaSizeChange.subscribe(this.setEventDimensions)
		this.periodChangeSub = this.blAgendaPeriod.change.subscribe(() => this.handlePeriodChange())
		this.eventsChangeSub = this.blAgendaEventsChange.subscribe(() => this.handleEventsChange())
		this.setHourIndicator()
	},
	unmounted() {
		this.periodChangeSub.unsubscribe()
		this.resizeSub.unsubscribe()
		this.eventsChangeSub.unsubscribe()
		if(this.setHourIndicatorTimeout) clearTimeout(this.setHourIndicatorTimeout)
	},
	inject: ['blAgendaEventsChange', 'blAgendaChangePeriod', 'blAgendaPeriod', 'blAgendaEvents', 'blAgendaSizeChange', 'blAgendaOptions', 'blAgendaPreviewEvent', 'blAgendaEventForm', 'blAgendaClosePreview', 'blAgendaEventDayUid'],
	methods: {
		generateDataTable() {
			this.allDayEventsHeight = 0
			this.data = []
			const today = (new Date()).toDateString()
			const start = new Date(this.blAgendaPeriod.start)
			const end = this.blAgendaPeriod.end
			const showWK = this.blAgendaOptions().showWeekends
			const singleDay = start.toDateString() == end.toDateString()
			while(start <= end) {
				if(!showWK && [6, 0].includes(start.getDay()) && !singleDay) {
					start.setDate(start.getDate() + 1)
					continue
				}
				let line = {
					index: start.toDateString(),
					uid: this.blAgendaEventDayUid(start),
					dayName: DateFormat.getDayName(start, 'medium'),
					date: new Date(start),
					today: start.toDateString() == today,
					day: start.getDate(),
					events: [],
					hourEvents: []
				}
				this.data.push(line)
				start.setDate(start.getDate() + 1)
			}
			this.$nextTick(() => this.scrollbarWidth = this.$refs.hourTableContainer.offsetWidth - this.$refs.hourTableContainer.clientWidth + 1)
		},
		setEventDimensions() {
			const cellWidth = this.$el.querySelector('table thead tr th:last-child').getBoundingClientRect().width
			const cellHeight = 100
			const events = this.data.map(d => d.events).flat()
			const ret = AgendaGrid.month(events, cellWidth, cellHeight)
			this.eventDisplayOptions = ret.eventDisplayOptions
			this.showMoreEvents = ret.showMoreEvents

			//Whole day max size
			this.allDayEventsHeight = 0
			for(let item of Object.values(this.eventDisplayOptions)) {
				const top = (parseInt(item.top.replace('px', '')) + 24) - 1
				if(top > this.allDayEventsHeight) this.allDayEventsHeight = top
			}

			//Hour events
			for(let day of this.data) this.eventDisplayOptions = {...this.eventDisplayOptions, ...AgendaGrid.day(day.hourEvents, cellWidth - 1, 51)}
		},
		handlePeriodChange() {
			this.generateDataTable()
		},
		handleEventsChange() {
			for(let event of this.blAgendaEvents()) {
				for(let item of this.data) {
					if(item.index == event.daysList[0]) {
						if(event.daysList.length == 1 && !event.wholeDay) item.hourEvents.push(event)
						else item.events.push(event)
						break
					}
				}
			}
			this.setEventDimensions()
		},
		setHourIndicator() {
			const now = new Date
			this.hourIndicatorHeight = ((now.getHours() + (now.getMinutes() / 60)) * 51) - 4
			this.setHourIndicatorTimeout = setTimeout(() => this.setHourIndicator(), 1000 * 60)
		},
		moreEventsDialog(day, event) {
			this.blAgendaClosePreview()
			const containerBox = event.target.parentElement.parentElement.getBoundingClientRect()
			this.moreEventDialogItem = day
			this.moreEventDialogItemStyle = {
				width: containerBox.width + 'px',
				left: (containerBox.left - 10) + 'px',
				top: (containerBox.top + 55) + 'px'
			}
		}
	}
}
</script>

<style scoped lang="scss">
	table {
		border-collapse: collapse;
		width: 100%;

		thead tr th.dayHeader {
			border-left: 1px solid var(--bl-border);

			> div {
				cursor: pointer;

				> em {
					text-align: center;
					font-style: normal;
					color: var(--bl-legend);
					text-transform: uppercase;
					font-family: 'Product sans';
					font-weight: 600;
					font-size: 11px;
					display: block;
					padding-top: 3px;
				}

				> h1 {
					text-align: center;
					font-family: 'Product sans';
					font-size: 27px;
					font-weight: normal;
					display: inline-block;
					min-width: 34px;
					margin-top: 2px;
				}
			}

			.hideBorder {
				background-color: var(--bl-surface);
				width: 1px;
				height: 58px;
				margin-top: -63px;
				position: absolute;
				margin-left: -1px;
			}
		}

		thead tr th.dayHeader.today {
			em {
				color: var(--bl-secondary);
			}

			h1 {
				background-color: var(--bl-secondary);
				color: var(--bl-on-secondary);
				border-radius: 100px;
			}
		}

		.agendaViewMonthEvents {
			min-height: 20px;
			margin-top: 5px;
			margin-left: 1px;

			> div {
				user-select: none;

				> * {
					pointer-events: none;
				}
			}
		}

		thead th {
			padding: 0;
		}

		thead th.hourLabel > div {
			border-bottom: 1px solid var(--bl-border);
			margin-right: 6px;
			visibility: hidden;
		}

		tbody {
			tr:last-child td {
				border-bottom: none;
			}

			tr:first-child td {
				border-top: none;
			}

			th {
				width: 1px;
			}

			td {
				position: relative;
				border: 1px solid var(--bl-border);
				height: 50px;
				vertical-align: top;
				padding: 0 1px;
			}
		}

		.hourIndicator {
			--hour-indicator-color: rgb(234, 67, 53);
			border-top: 1px solid var(--hour-indicator-color);
			border-bottom: 1px solid var(--hour-indicator-color);
			width: calc(100% - 1px);
			position: absolute;
			z-index: 5;
		}

		.hourIndicator::before {
			content: ' ';
			width: 13px;
			height: 13px;
			display: block;
			background-color: var(--hour-indicator-color);
			position: absolute;
			border-radius: 50%;
			margin-top: -6px;
			margin-left: -8px;
		}

		.hourEvent {
			position: absolute;
			display: flex;
			z-index: 2;
			align-items: flex-start;
			border-radius: 4px;
			font-size: 12px;
			cursor: pointer;
			transition: background-color .2s;
			margin: 0 1px;
			transition: background-color .2s, box-shadow .1s;
			overflow: hidden;
			user-select: none;

			> * {
				pointer-events: none;
			}

			> div:not(.blProfilePicture) {
				flex: 1;
				color: white;
				padding: 4px 5px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: 500;
			}

			> div.blProfilePicture {
				margin: 5px 5px 0 5px;
			}
		}

		.hourEvent.previewed {
			box-shadow: 1px 1px 4px 1px #00000075;
			z-index: 3;
		}
	}

	.hourLabel > div {
		text-align: right;
		margin: -32px 4px 0 8px;
		font-weight: normal;
		font-size: 11px;
		color: var(--bl-legend);
	}
</style>