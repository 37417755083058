<template>
	<div class="eventPreviewContainer">
		<BlView model="agendaevent" :modelId="event.realId" @modelValue="updatedView($event)" v-if="showFields">
			<div class="actionsContainer">
				<button class="bl-icon-button" @click="blAgendaEventForm(null, event.realId)" v-if="event.level == 2">edit</button>
				<button class="bl-icon-button" @click="deleteEvent()" v-if="event.level == 2">delete</button>
				<button class="bl-icon-button" @click="blAgendaClosePreview()">close</button>
			</div>
			<h1>
				<span :style="{backgroundColor: event.color}"></span>
				<div>{{ event.title }}</div>
			</h1>
			<h3>{{ subTitle }}</h3>
			<ul :class="{loading: !blViewLoaded}">
				<!-- <li class="textField">
					<icon>calendar_today</icon>
					<BlViewField name="calendar.name" :hideEmpty="true" :hideLabel="true" />
				</li>
				<li v-if="event.timezone">
					<icon>public</icon>
					<span>{{ event.timezone }}</span>
				</li>
				<BlViewFields name="reminders" v-slot="reminder">
				<li :class="{reminderDone: reminder.done}">
					<BlViewField name="done" :hide="true" />
					<BlViewField name="timeAmount" :hide="true" />
					<BlViewField name="timeType" :hide="true" />
					<icon>{{ reminder.done ? 'notifications_off' : 'notifications' }}</icon>
					<span>{{ reminder.timeAmount }} {{ reminder.timeType }}</span>
				</li>
				</BlViewFields>
				<li>
					<BlProfilePicture class="profilePicture" :id="event.owner.id" size="25" :showStatus="false" />
					<span>{{ event.owner.__toString }}</span>
				</li>
				<li class="blViewFieldContainer">
					<icon>event_note</icon>
					<BlViewField name="notes" :hideEmpty="true" :hideLabel="true" />
				</li>
				<li class="blViewFieldContainer" v-show="eventData.location?._cp?.lat">
					<icon>location_on</icon>
					<BlViewField style="width: 100%;" name="location" :hideEmpty="true" :hideLabel="true" />
				</li>
				<li class="blViewFieldContainer">
					<icon>folder_open</icon>
					<BlViewField name="files" :hideEmpty="true" :hideLabel="true" />
				</li> -->
				<AgendaeventPreviewContent :event="event" :eventData="eventData" />
			</ul>
		</BlView>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { Dialog } from 'InterfaceBundle'
import dayjs from 'dayjs'

export default {
	name: 'AgendaEventPreview',
	props: ['event'],
	emits: ['loaded'],
	inject: ['blAgendaClosePreview', 'blAgendaChangePeriod', 'blAgendaEventForm'],
	data() {
		return {
			subTitle: '',
			eventData: {},
			blViewLoaded: false,
			showFields: false
		}
	},
	watch: {
		event: function() {
			this.initialize()
		}
	},
	created() {
		this.initialize()
	},
	mounted() {
		this.keydownHandlerCb = e => this.keydownHandler(e)
		window.addEventListener('keydown', this.keydownHandlerCb, false)
	},
	unmounted() {
		window.removeEventListener('keydown', this.keydownHandlerCb)
	},
	methods: {
		initialize() {
			this.blViewLoaded = false
			this.showFields = false
			this.$nextTick(() => this.showFields = true)
			if(this.event.start.toISOString().substr(0, 10) == this.event.end.toISOString().substr(0, 10)) {
				this.subTitle = dayjs(this.event.start).format('ddd D MMMM')
				if(this.event.startTime) this.subTitle += ' ⋅ ' + this.event.startTime + ' - ' + this.event.endTime
			}
			else {
				this.subTitle = dayjs(this.event.start).format('ddd D MMMM')
				if(this.event.startTime) this.subTitle += ' ' + this.event.startTime
				this.subTitle += ' - '
				this.subTitle += dayjs(this.event.end).format('ddd D MMMM')
				if(this.event.endTime) this.subTitle += ' ' + this.event.endTime
			}
		},
		keydownHandler(e) {
			if(e.keyCode == 46) this.deleteEvent()
		},
		deleteEvent() {
			Dialog.confirm({
				title: 'Delete event ?',
				accept: 'Delete',
				cancel: 'Cancel'
			}).then(() => {
				let req = {}
				req['context("result"):s.agendaevent.archive(' + this.event.realId + ')'] = {
					result: 'local.result'
				}
				Api.post('/api/structure/', req)
			})
		},
		updatedView(event) {
			this.eventData = event
			this.blViewLoaded = true
			this.$nextTick(() => this.$emit('loaded'))
		}
	}
}
</script>

<style scoped lang="scss">
	.eventPreviewContainer {
		min-width: 470px;
		max-width: 470px;
		width: 470px;
		padding: 10px;
	}

	.actionsContainer {
		display: flex;
		justify-content: flex-end;
	}

	h1 {
		display: flex;
		align-items: center;
		font-family: 'Product sans';
		color: var(--bl-on-surface);
		font-size: 20px;
		margin: 0 10px 5px 10px;
		padding: 0;

		span {
			min-width: 14px;
			min-height: 14px;
			border-radius: 4px;
			margin-right: 8px;
		}
	}

	h3 {
		font-family: 'Product sans';
		font-weight: 400;
		font-size: 14px;
		margin: 0 10px 10px 10px;
		color: var(--bl-legend);
		text-transform: capitalize;
	}

	:deep ul {
		list-style: none;
		margin: 0;
		padding: 10px 0 0 0;

		li {
			display: flex;
			align-items: top;
			padding: 12px 10px;

			> icon, .profilePicture {
				margin-top: -3px;
				color: var(--bl-legend);
				font-size: 22px;
				margin-right: 8px;
			}
			
			.profilePicture {
				margin-top: -4px;
				margin-left: -1px;
				margin-right: 9px;
			}
		}

		li.reminderDone {
			color: var(--bl-legend);
			opacity: .8;

			> span {
				text-decoration: line-through;
			}
		}

		li:has(.bl-view-field:empty) {
			display: none;
		}

		.bl-view-field {
			margin-top: -10px;
		}

		li.textField .bl-view-field {
			margin-top: -4px;
		}
	}

	:deep ul.loading li.blViewFieldContainer {
		display: none;
	}
</style>