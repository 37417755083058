<template>
	<div style="display: flex; height: 100%; width: 100%;">
		<div style="width: 255px; display: flex; flex-direction: column;" v-if="meta && !widget">
			<BlButton :label="$t('agenda.event.create')" icon="calendar_add_on" @click="eventForm()" class="mainButton" />
			<div class="bl-card" style="margin-right: 0; flex: 1;">
				<div class="inlineCalendarContainer">
					<BlCalendarInput :opened="true" @change="setDate($event)" :value="period.date" :disableBodyAppend="true" />
				</div>
				<AgendaContainerCalendars @change="changePeriod(0)" />
			</div>
		</div>
		<div class="bl-card agendaContainer" ref="agendaContainer" v-if="meta" :class="{widget: widget}">
			<h1 v-if="widget">{{ period.dateFormatted }}</h1>
			<div class="header">
				<BlButton class="outlined dense" :label="$t('agenda.today')" @click="setDate()" style="color: var(--bl-legend); margin-left: 6px;" />
				<span style="flex: 1" v-if="widget"></span>
				<button class="bl-icon-button" @click="changePeriod(-1)" style="margin-right: -10px;">navigate_before</button>
				<button class="bl-icon-button" @click="changePeriod(1)">navigate_next</button>
				<h1 v-if="!widget">{{ period.dateFormatted }}</h1>
				<span style="flex: 1" v-if="!widget"></span>
				<div v-bl-dropdown="searchDropdownOptions" class="searchContainer" ref="searchContainer">
					<div v-bl-input :class="{hasResults: searchResults.length}">
						<input type="text" v-model="searchTerm" @input="search()" @keydown.enter="openSearchItem(searchResults[searchDropdownOptions.value.activeIndex])" ref="searchInput" />
						<icon class="suffix">search</icon>
					</div>
					<ul>
						<li v-for="item in searchResults" :key="item.id" @click="openSearchItem(item)">
							<div>
								<span :style="{backgroundColor: item.color}"></span>
								<div>
									<b>{{ item.title }}</b>
									<em>{{ item.fromDate }}</em>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<BlMenu v-if="!widget">
					<template #hook>
						<BlButton class="outlined viewTypeMenu" :label="$t('agenda.type.' + periodType.id)" icon="expand_more" style="color: var(--bl-legend)" />
					</template>
					<ul>
						<li v-for="type in periodTypes" :key="type.id" @click="setPeriodType(type)">
							<icon>{{ type.icon }}</icon> {{ $t('agenda.type.' + type.id) }}
						</li>
					</ul>
				</BlMenu>
				<BlMenu v-if="!widget">
					<template #hook>
						<button class="bl-icon-button" type="button" v-bl-tooltip="'Actions'">more_vert</button>
					</template>
					<ul>
						<li>
							<icon>import_export</icon> {{ $t('agenda.export') }}
						</li>
						<li v-link="'agendacalendar/list'">
							<icon>edit_calendar</icon> {{ $t('agenda.manageCalendar') }}
						</li>
						<li v-link="'agenda/settings'">
							<icon>settings</icon> {{ $t('agenda.settings') }}
						</li>
					</ul>
				</BlMenu>
			</div>
			<AgendaMonthView v-if="periodType.id == 3" />
			<AgendaWeekView v-if="periodType.id == 2 || periodType.id == 1" />
		</div>
		<dialog open
			v-if="previewedEvent"
			class="previewedEvent bl-card"
			:class="{previewedEventDisableTransition: previewedEventDisableTransition}"
			ref="previewedEvent"
			:style="{left: previewedEventPosition.left + 'px', top: previewedEventPosition.top + 'px'}"
		>
			<AgendaEventPreview :event="previewedEvent" @loaded="setPreviewedEventPosition(true)" />
		</dialog>
	</div>
</template>

<script>
import AgendaContainerCalendars from './AgendaContainerCalendars'
import { Api, ModelChangeEventHelpers } from 'ModelBundle'
import { DateFormat, EventEmitter, Dialog, Router } from 'InterfaceBundle'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)
import isoWeek from 'dayjs/plugin/isoWeek'
dayjs.extend(isoWeek)

export default {
	name: 'AgendaContainer',
	components: { AgendaContainerCalendars },
	props: {
		widget: {
			type: Boolean,
			default: false
		},
		focusEvent: {
			type: Number
		}
	},
	data() {
		return {
			meta: null,
			period: {
				start: null,
				end: null,
				date: new Date(),
				dateFormatted: null,
				change: new EventEmitter(),
				allDays: DateFormat.dayMedium,
			},
			sizeChange: new EventEmitter(),
			periodType: null,
			periodTypes: [
				{icon: 'calendar_view_day', id: 1, split: 'd'},
				{icon: 'calendar_view_week', id: 2, split: 'W'},
				{icon: 'calendar_view_month', id: 3, split: 'W'}
			],
			events: [],
			eventsChange: new EventEmitter(),
			previewedEvent: null,
			previewedEventPosition: {},
			previewedEventDisableTransition: true,
			searchDropdownOptions: {
				input: 'input',
				list: 'ul',
				value: {}
			},
			searchResults: [],
			searchTerm: ''
		}
	},
	created() {
		console.log('create agenda')
		this.loadMeta().once(() => {
			this.setPeriodType(this.periodTypes.filter(p => p.id == this.meta.periodType)[0])
			if(this.focusEvent) {
				let req = {}
				req['context("event"):model.get("agendaevent").findOneById(' + this.focusEvent + ')'] = {
					id: null,
					fromDateIso: 'local.event.getFromDate().format("c")',
				}
				Api.post('api/structure/', req).then(resp => this.openSearchItem(resp))
			}
			else {
				this.period.date = new Date(this.meta.periodDate.date)
				this.changePeriod(0)
			}
			this.autoClosePreviewedElementCb = e => this.autoClosePreviewedElement(e)
			document.addEventListener('click', this.autoClosePreviewedElementCb)

			this.rtListener = ModelChangeEventHelpers.listen('public.agendaevent')
			this.rtListener.subscribe(() => this.changePeriod(0))
		})
	},
	mounted() {
		this.$nextTick(() => this.period.change.emit())
		this.resizeObserver = new ResizeObserver(() => this.sizeChange.emit())
		this.resizeObserver.observe(this.$el)
	},
	unmounted() {
		if(this.rtListener) ModelChangeEventHelpers.unsubscribe(this.rtListener)
		document.removeEventListener('click', this.autoClosePreviewedElementCb)
		this.resizeObserver.disconnect()
	},
	methods: {
		setPeriodType(type) {
			this.periodType = type
			this.$nextTick(() => this.changePeriod(0))
		},
		setPeriods() {
			if(this.periodType.id == 3) {
				this.period.start = new Date(this.period.date.getFullYear(), this.period.date.getMonth(), 1)
				this.period.start.setDate(this.period.start.getDate() - DateFormat.getLocalDay(this.period.start))
				this.period.end = new Date(this.period.date.getFullYear(), this.period.date.getMonth() + 1, 0)
				this.period.end.setDate(this.period.end.getDate() + 6 - DateFormat.getLocalDay(this.period.end))
				this.period.dateFormatted = DateFormat.getMonthName(this.period.date) + ' ' + this.period.date.getFullYear()
			}
			else if(this.periodType.id == 2) {
				this.period.start = new Date(this.period.date.getTime())
				this.period.start.setDate(this.period.start.getDate() - DateFormat.getLocalDay(this.period.start))
				this.period.end = new Date(this.period.date.getTime())
				this.period.end.setDate(this.period.end.getDate() + 6 - DateFormat.getLocalDay(this.period.end))
				this.period.dateFormatted = DateFormat.getMonthName(this.period.date) + ' ' + this.period.date.getFullYear()
				if(this.meta.showWeekNumbers) this.period.dateFormatted += ' (' + this.$t('agenda.weekTitle') + ' ' + dayjs(this.period.date).format('W') + ')'
			}
			else if(this.periodType.id == 1) {
				this.period.start = new Date(this.period.date.getTime())
				this.period.end = new Date(this.period.date.getTime())
				this.period.dateFormatted = DateFormat.getDayName(this.period.date, 'long') + ' ' + this.period.date.getDate() + ' ' + DateFormat.getMonthName(this.period.date) + ' ' + this.period.date.getFullYear()
			}
		},
		changePeriod(way, cb = null) {
			this.closeEvent()
			this.previewedEvent = null
			if(this.periodType.id == 3) this.period.date.setMonth(this.period.date.getMonth() + way)
			else if(this.periodType.id == 2) this.period.date.setDate(this.period.date.getDate() + (way * 7))
			else if(this.periodType.id == 1) {
				this.period.date.setDate(this.period.date.getDate() + way)
				if(way && !this.meta.showWeekends && [6, 0].includes(this.period.date.getDay())) {
					this.changePeriod(way, cb)
					return
				}
			}

			this.period.date = new Date(this.period.date)//Force new object to trigger change detection
			this.setPeriods(way)
			this.period.change.emit(way)
			this.events = []
			Api.get('agenda/', {
				from: this.period.start,
				to: this.period.end,
				split: this.periodType.split,
				period: JSON.stringify({
					type: this.periodType.id,
					date: Api._formatDate(this.period.date).substr(0, 10)
				}),
				widget: this.widget
			}, {}, 'agendaevents').then(resp => {
				this.events = resp.map(e => {
					e.start = new Date(e.start)
					e.end = new Date(e.end)
					return e
				})
				this.initializeEvents()
				this.eventsChange.emit(this.events)
				if(cb) cb()
			})
		},
		setDate(date = null, cb = null) {
			if(!date) date = new Date()
			if(this.period.date.toDateString() != date.toDateString()) {
				this.period.date = date
				this.changePeriod(0, cb)
			}
			else if(cb) cb()
		},
		initializeEvents() {
			for(let event of this.events) {
				let days = []
				for(let i = 0; i < event.days; i++) {
					let d = new Date(event.start.getTime())
					d.setDate(d.getDate() + i)
					if(this.meta.showWeekends || ![6, 0].includes(d.getDay()) || this.periodType.id == 1) days.push(d.toDateString())
				}
				event.daysList = days
			}
		},
		previewEvent(event, element, moreEvents = false) {
			if(this.widget) {
				Router.navigate(['agenda'])
				return
			}
			if(!this.previewedEvent) this.previewedEventDisableTransition = true
			else if(this.previewedEvent.realId == event.realId) {
				this.closeEvent()
				return
			}
			this.closeEvent()
			this.previewedEvent = event
			this.previewedEventElement = element
			if(moreEvents) event.moreEventsPreviewed = true
			else event.previewed = true
			setTimeout(() => this.setPreviewedEventPosition(), 10)
		},
		setPreviewedEventPosition(enableTransition = false) {
			if(enableTransition) this.previewedEventDisableTransition = false
			if(!this.previewedEventElement) return
			const containerBox = {
				left: this.$refs.agendaContainer.getBoundingClientRect().left,
				right: this.$refs.agendaContainer.getBoundingClientRect().right,
				top: this.$refs.agendaContainer.getBoundingClientRect().top + 50,
				bottom: this.$refs.agendaContainer.getBoundingClientRect().bottom - 10
			}
			const elementBox = this.previewedEventElement.getBoundingClientRect()
			const eventEl = this.$refs.previewedEvent

			//By default set to center left
			this.previewedEventPosition = {
				left: elementBox.left - eventEl.offsetWidth - 10,
				top: elementBox.top + (this.previewedEventElement.offsetHeight / 2) - (eventEl.offsetHeight / 2) - 10
			}

			//Top container check
			if(this.previewedEventPosition.top < containerBox.top) this.previewedEventPosition.top = containerBox.top

			//Bottom container check
			if((this.previewedEventPosition.top + eventEl.offsetHeight) > containerBox.bottom) this.previewedEventPosition.top = containerBox.bottom - eventEl.offsetHeight

			//Fallback to center right if outside of bounds
			if(this.previewedEventPosition.left < containerBox.left) {
				this.previewedEventPosition.left = elementBox.right + 5
			}

			//Fallback to below center if outside
			if((this.previewedEventPosition.left + eventEl.offsetWidth) > containerBox.right) {
				this.previewedEventPosition = {
					left: (elementBox.right - elementBox.left) / 2 + (eventEl.offsetWidth / 2),
					top: elementBox.bottom + 5
				}

				//Fallback to above center if outside
				if((this.previewedEventPosition.top + eventEl.offsetHeight) > containerBox.bottom) this.previewedEventPosition.top = elementBox.top - eventEl.offsetHeight - 15
			}
		},
		closeEvent() {
			if(this.previewedEvent) {
				this.previewedEvent.previewed = false
				this.previewedEvent.moreEventsPreviewed = false
			}
			this.previewedEvent = null
			this.previewedEventElement = null
			this.previewedEventPosition = {}
		},
		autoClosePreviewedElement(event) {
			if(this.$refs.previewedEvent && !event.target.hasAttribute('bl-agenda-event') && !this.$refs.previewedEvent.contains(event.target)) {
				event.stopPropagation()
				this.closeEvent()
			}
		},
		eventForm(at = null, eventId = null, atTime = null) {
			if(this.previewedEvent) {
				if(eventId) this.closeEvent()
				else return
			}
			Dialog.custom({
				component: 'AgendaeventForm',
				componentProps: {dialog: true, defaultDate: at, eventId: eventId, defaultTime: atTime},
				required: true
			})
		},
		loadMeta() {
			const ret = new EventEmitter()
			let req = {}
			req['context("settings"):s.agendasettings.get(' + (this.widget ? 'true' : 'false') + ')'] = {
				showWeekends: null,
				showWeekNumbers: null,
				periodType: null,
				periodDate: null,
				hiddenUsers: 'local.settings.getHiddenUsers().get("id")',
				collapsedUsers: 'local.settings.getCollapsedUsers().get("id")'
			}
			Api.post('api/structure/', req).then(resp => {
				this.meta = resp
				ret.emit()
			})
			return ret
		},
		search() {
			if(!this.searchTerm) this.searchResults = []
			else {
				let req = {}
				req['context("events"):s.agenda.getEventsForUser(null, null, "' + this.searchTerm + '")'] = {
					'loop("event"):local.events': {
						id: null,
						title: null,
						fromDate: 'local.event.getFromDate(true)',
						fromDateIso: 'local.event.getFromDate().format("c")',
						color: 'local.event.calendar.color'
					}
				}
				Api.post('api/structure/', req, {}, 'agendaeventsearch').then(resp => {
					this.searchResults = resp
				})
			}
		},
		openSearchItem(item) {
			this.searchDropdownOptions.methods.closeList()
			const date = new Date(item.fromDateIso)
			this.setDate(date, () => {
				const matchEvent = this.events.filter(e => e.realId == item.id)
				if(!matchEvent.length) return
				setTimeout(() => {
					const element = this.$el.querySelector('#blAgendaEvent_' + item.id)
					if(element && element.style.display != 'none') this.$nextTick(() => this.previewEvent(matchEvent[0], element))
					//Handle show more
					else {
						const showMoreEl = this.$el.querySelector('#blAgendaEventShowMore_' + this.generateDayUid(date))
						if(showMoreEl) {
							showMoreEl.click()
							this.$nextTick(() => {
								const element = this.$el.querySelector('#blAgendaEventMore_' + item.id)
								if(element) this.$nextTick(() => this.previewEvent(matchEvent[0], element, true))
							})
						}
					}
				})
			})
			document.activeElement.blur()
		},
		generateDayUid(day) {
			return day.getDate() + '_' + day.getMonth() + '_' + day.getFullYear()
		}
	},
	provide() {
		return {
			blAgendaChangePeriod: this.changePeriod,
			blAgendaEventsChange: this.eventsChange,
			blAgendaSizeChange: this.sizeChange,
			blAgendaEvents: () => this.events,
			blAgendaPeriod: this.period,
			blAgendaOptions: () => this.meta,
			blAgendaPreviewEvent: (ev, el, moreEvents = false) => this.previewEvent(ev, el, moreEvents),
			blAgendaClosePreview: () => this.closeEvent(),
			blAgendaEventForm: (at, eventId = null, atTime = null) => this.eventForm(at, eventId, atTime),
			blAgendaEventDayUid: day => this.generateDayUid(day)
		}
	}
}
</script>

<style scoped lang="scss">
	.agendaContainer {
		padding: 0;
		flex: 1;
		height: calc(100% - 7px);
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	.agendaContainer.widget {
		border: 0;
	}

	.header {
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--bl-border);
	}

	button.outlined.viewTypeMenu {
		border: 1px solid var(--bl-border);
		margin: 0 5px;

		:deep > icon {
			float: right;
			font-size: 22px;
			line-height: 18px;
			margin: 0 4px 0 -6px;
		}

		:deep > span {
			flex: 1;
		}
	}

	.inlineCalendarContainer {
		position: relative;
		margin-top: -5px;
		transform: scale(0.85);
		transform-origin: top left;

		:deep .calendar {
			animation: none;
			box-shadow: none;
			position: initial;
		}
	}

	.previewedEvent {
		position: absolute;
		z-index: 10;
		margin: 5px;
		padding: 0;
		transition: .2s;
		box-shadow: 0px 0px 16px 3px rgba(0,0,0,0.2);
		background-color: color-mix(in srgb, var(--bl-surface), transparent 30%);
		backdrop-filter: blur(20px);
	}

	.previewedEvent:not(.previewedEventDisableTransition) {
		animation: previewEventPopIn .1s;
	}

	.previewedEvent.previewedEventDisableTransition {
		transition: 0s !important;
		visibility: hidden;
	}

	@keyframes previewEventPopIn {
		from {
			transform: scale(.8);
			opacity: .3;
		}

		to {
			transform: scale(1);
			opacity: 1;
		}
	}

	button.mainButton {
		width: calc(100% - 5px);
		margin: 5px 5px 0 5px;
		height: 50px;
		font-weight: 400;
		font-family: 'Product sans';

		:deep > i {
			font-size: 24px;
		}

		:deep > span {
			line-height: 25px;
		}
	}

	.searchContainer {
		> div {
			margin-top: -6px;
			margin-right: 20px;
			width: 250px;

			> input {
				padding-bottom: 9px;
			}

			> icon.suffix {
				visibility: visible;
			}
		}

		> div.hasResults input:focus {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		> ul {
			position: absolute;
			list-style: none;
			background-color: var(--bl-surface);
			margin: 0 0 0 -2px;
			border: 2px solid var(--bl-primary);
			border-top: 1px solid var(--bl-border);
			border-bottom-left-radius: var(--bl-border-radius);
			border-bottom-right-radius: var(--bl-border-radius);
			padding: 0;
			z-index: 1;
			width: 266px;
			overflow: hidden;
			visibility: hidden;

			li {
				padding: 7px;
				margin: 0;
				cursor: pointer;
				user-select: none;

				> div {
					display: flex;
					gap: 10px;
					align-items: center;

					> span {
						border-radius: 20px;
						width: 10px;
						height: 10px;
					}

					> div {
						display: flex;
						flex-direction: column;

						b {
							font-family: 'Product sans';
							font-weight: 600;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						em {
							font-style: normal;
							color: var(--bl-legend);
							font-size: 12px;
						}
					}
				}
			}

			li.hovered {
				background-color: var(--bl-background);
			}
		}

		> ul:has(li) {
			visibility: visible;
		}
	}
</style>