<template>
	<div class="container">
		<div v-if="groupByDate.length == 0" class="empty">
			<i class="material-icons">emoji_food_beverage</i>
			<div>{{ $t('notifications.noNotifications') }}</div>
		</div>
		<div v-else class="actions">
			<BlButton :label="$t('notifications.seenAll')" icon="clear_all" class="dense outlined legend" @click="markAllSeen()" />
			<BlButton icon="delete_sweep" class="dense outlined legend" v-bl-tooltip="$t('notifications.deleteAll')" @click="deleteAll()" />
		</div>
		<div v-for="group in groupByDate" :key="group.label">
			<div class="date">{{ group.label }}</div>
			<div class="notification" v-for="notification in group.items" :key="notification.id" :class="{hover: notification.opened, new: !notification.seen}" @click="openNotification($event, notification)">
				<BlProfilePicture v-if="notification.user" :id="notification.user.id" style="margin-right: 7px;" />
				<i v-if="!notification.user && notification.icon" class="material-icons" :style="{backgroundColor: notification.color}">{{ notification.icon }}</i>
				<div class="content">
					<div class="title" v-html="notification.title"></div>
					<div class="body" v-if="notification.body" v-html="notification.body"></div>
					<span>{{ notification.user ? notification.user.label : '' }} <b style="font-weight: 500;">#{{ notification.channel }}</b></span>
				</div>
				<bl-menu @change="notification.opened = $event">
					<template v-slot:hook>
						<span class="bl-icon-button menu-icon">more_vert</span>
					</template>
					<ul>
						<li @click="unseen(notification)" v-if="notification.seen"><i class="material-icons">mark_as_unread</i>{{ $t('notifications.markAsUnseen') }}</li>
						<li @click="deleteNotification(notification)"><i class="material-icons">delete</i>{{ $t('notifications.delete') }}</li>
						<!-- <li @click="unsubscribe(notification)"><i class="material-icons">unsubscribe</i>{{ $t('notifications.unsubscribe') }}&nbsp;<b style="font-weight: 500;">#{{ notification.channel }}</b></li> -->
					</ul>
				</bl-menu>
				<div class="time">{{ notification.time }}</div>
				<div class="actions" v-if="notification.actions && notification.actions.length">
					<BlButton v-for="action in notification.actions" :key="action.icon" class="outlined dense primary" :icon="action.icon" :label="action.label" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog, Router, Realtime } from 'InterfaceBundle'
import { Api } from 'ModelBundle'
import dayjs from 'dayjs'

export default {
	name: 'BlInterfaceNotificationContainer',
	data() {
		return {
			opened: false,
			groupByDate: [],
			notifications: []
		}
	},
	created() {
		this.loadData()
		this.rt = Realtime.listen('interface.notificationscount')
		this.rt.then(() => this.loadData())
	},
	unmounted() {
		this.rt.unsubscribe()
	},
	methods: {
		loadData() {
			let req = {
				'context("notifications"):s.interface.notifications.get()': {
					notifications: 'local.notifications'
				}
			}
			Api.post('/api/structure/', req).then(resp => {
				this.notifications = resp.notifications.map(n => {
					n.date = new Date(n.date)
					return n
				})
				this.groupNotifications()
			})
		},
		groupNotifications() {
			let today = dayjs().format('DDMMYYYY')
			let yesterday = new Date()
			yesterday.setDate(yesterday.getDate() - 1)
			yesterday = dayjs(yesterday).format('DDMMYYYY')
			this.groupByDate = {}
			for(let notification of this.notifications) {
				notification.time = dayjs(notification.date).format('HH:mm')
				let dateKey = dayjs(notification.date).format('DDMMYYYY')
				if(dateKey == today) dateKey = this.$t('Today')
				else if(dateKey == yesterday) dateKey = this.$t('Yesterday')
				else dateKey = dayjs(notification.date).format('DD/MM/YYYY')
				if(!this.groupByDate[dateKey]) this.groupByDate[dateKey] = {label: dateKey, items: []}
				this.groupByDate[dateKey].items.push(notification)
			}
			this.groupByDate = Object.values(this.groupByDate)
		},
		unsubscribe(notification) {
			Dialog.confirm({
				title: this.$t('notifications.unsubscribeDialog.title{channel}', {channel: '#' + notification.channel}),
				accept: this.$t('notifications.unsubscribeDialog.accept'),
				cancel: this.$t('notifications.unsubscribeDialog.cancel'),
				required: false
			}).then(() => {
				console.log(notification)
			}, () => {
			})
		},
		deleteNotification(notification) {
			Dialog.confirm({
				title: this.$t('notifications.deleteDialog.title'),
				accept: this.$t('notifications.deleteDialog.accept'),
				cancel: this.$t('notifications.deleteDialog.cancel'),
				required: false
			}).then(() => {
				let req = {}
				req['context("notifications"):s.interface.notifications.delete(' + notification.id + ')'] = {}
				Api.post('/api/structure/', req)
				this.notifications = this.notifications.filter(n => n.id != notification.id)
				this.groupNotifications()
				this.$forceUpdate()
			}, () => {
			})
		},
		unseen(notification) {
			notification.seen = false
			let req = {}
			req['context("notifications"):s.interface.notifications.unseen(' + notification.id + ')'] = {}
			Api.post('/api/structure/', req)
		},
		openNotification(event, notification) {
			notification.seen = true
			let req = {}
			req['context("notifications"):s.interface.notifications.seen(' + notification.id + ')'] = {}
			Api.post('/api/structure/', req)
			if(event.composedPath()[0].tagName == 'A') {
				let route = event.composedPath()[0].getAttribute('v-link')
				if(route) {
					Router.navigate(route)
					return
				}
				else {
					event.preventDefault()
					event.stopPropagation()
				}
			}
			if(notification.route) Router.navigate(notification.route)
		},
		markAllSeen() {
			Dialog.confirm({
				title: this.$t('notifications.markAllSeenDialog.title'),
				accept: this.$t('notifications.markAllSeenDialog.accept'),
				cancel: this.$t('notifications.markAllSeenDialog.cancel'),
				required: false
			}).then(() => {
				this.notifications.forEach(n => n.seen = true)
				Api.post('/api/structure/', {'context("notifications"):s.interface.notifications.seenAll()': {}})
			})
		},
		deleteAll() {
			Dialog.confirm({
				title: this.$t('notifications.deleteAllDialog.title'),
				accept: this.$t('notifications.deleteAllDialog.accept'),
				cancel: this.$t('notifications.deleteAllDialog.cancel'),
				required: false
			}).then(() => {
				this.notifications = []
				this.groupNotifications()
				Api.post('/api/structure/', {'context("notifications"):s.interface.notifications.deleteAll()': {}})
			})
		}
	}
}
</script>

<style scoped lang="scss">
	.container {
		min-width: 334px;
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: -25px;
		gap: 5px;
	}

	.empty {
		text-align: center;
		color: var(--bl-legend);

		div {
			font-size: 12px;
			margin-bottom: 60px;
		}

		i.material-icons {
			font-size: 50px;
			margin-top: 40px;
			margin-bottom: 5px;
		}
	}

	.date {
		color: var(--bl-legend);
		background-color: var(--bl-border);
		border-radius: var(--bl-border-radius);
		display: inline-block;
		margin-top: 10px;
		padding: 4px 7px;
		font-size: 11px;
	}

	.notification {
		position: relative;
		cursor: pointer;
		margin: 5px 0;
		overflow: hidden;
		border-radius: var(--bl-border-radius);
		border: 1px solid var(--bl-border);
		display: flex;
		flex-wrap: wrap;
		padding: 5px 5px 5px 5px;

		.menu-icon {
			transform: scale(.8);
			position: absolute;
			right: 0;
			margin-right: -5px;
			margin-top: -10px;
			opacity: 0;
			pointer-events: none;
			transition: opacity .1s;
			z-index: 4;
		}

		img {
			width: 35px;
			height: 35px;
			border-radius: 50%;
			margin-right: 7px;
		}

		i.material-icons {
			min-width: 35px;
			min-height: 29px;
			padding-top: 6px;
			max-width: 35px;
			max-height: 29px;
			border-radius: 50%;
			font-size: 23px;
			margin-right: 7px;
			text-align: center;
			color: white;
		}

		.time {
			font-weight: 500;
			color: var(--bl-legend);
			font-size: 11px;
			padding: 2px;
			opacity: 1;
			transition: opacity .1s;
		}

		.content {
			flex: 1;

			.title {
				margin-bottom: 2px;
			}

			.body {
				color: var(--bl-legend);
				font-size: 12px;
				margin-bottom: 5px;
			}

			span {
				font-size: 12px;
				color: var(--bl-legend);
			}
		}

		.actions {
			display: flex;
			justify-content: flex-end;
			flex: 1;
			min-width: 100%;

			button {
				margin-left: 5px;
			}
		}
	}

	.notification:hover, .notification.hover {
		.menu-icon {
			opacity: 1;
			pointer-events: all;
		}

		.time {
			opacity: 0;
		}
	}

	.notification.new {
		.title {
			font-weight: 500;
		}
	}

	.notification.new .content:before {
		content: ' ';
		background-color: var(--bl-primary);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		pointer-events: none;
		opacity: .1;
	}
</style>