/**
 * Agenda grid calculation
 * @type {Object}
 */
export const AgendaGrid = {
	eventHeight: 24,
	widthCorrection: 13,
	/**
	 * Generate month grid
	 */
	month(agendaEvents, cellWidth, cellHeight) {
		let eventDisplayOptions = {}
		let showMoreEvents = {}
		let position = {}
		let hiddenEventsByDay = {}
		for(let event of agendaEvents) {
			let initialPosition = null
			for(let d of event.daysList) {
				if(initialPosition === null) {
					if(!position[d]) position[d] = 0
					initialPosition = position[d]
					if((position[d] * this.eventHeight) <= cellHeight) position[d]++
				}
				else {
					if(!position[d]) position[d] = 0
					if(initialPosition !== null && (!position[d] || initialPosition >= position[d]) && ((initialPosition + 1) * this.eventHeight) <= cellHeight) position[d] = initialPosition + 1
				}

				if(!hiddenEventsByDay[d]) hiddenEventsByDay[d] = []
			}

			const d = event.daysList[0]
			const hide = (position[d] * this.eventHeight) > cellHeight
			if(hide) {
				for(let eventDay of event.daysList) hiddenEventsByDay[eventDay].push(event)
			}

			let width = ((cellWidth * event.daysList.length) - this.widthCorrection)
			if(event.startBefore) width -= 8
			if(event.endAfter) width -= 8
			let top = ((position[d] - 1) * this.eventHeight)
			if(top < 0) top = 0
			eventDisplayOptions[event.id] = {
				width: width + 'px',
				top: top + 'px',
				hide: hide
			}
			if(hide) position[d]--
		}

		for(let dayIndex in hiddenEventsByDay) {
			const events = hiddenEventsByDay[dayIndex]
			//If only one event hidden, and event start date is index and length is one (could be improved), show event
			if(events.length == 1 && events[0].daysList.length == 1 && events[0].daysList[0] == dayIndex) {
				eventDisplayOptions[events[0].id].hide = false
				//Remove event from other days
				for(let eventDay of events[0].daysList) hiddenEventsByDay[eventDay] = hiddenEventsByDay[eventDay].filter(e => e.id != events[0].id)
			}
			else if(events.length) {
				showMoreEvents[dayIndex] = {
					top: (cellHeight - (cellHeight % this.eventHeight)) + 'px',
					width: (cellWidth - this.widthCorrection) + 'px',
					count: events.length,
					events: events
				}
			}
		}

		return {
			eventDisplayOptions: eventDisplayOptions,
			showMoreEvents: showMoreEvents
		}
	},
	/**
	 * Generate day grid
	 */
	day(agendaEvents, cellWidth, cellHeight) {
		let grid = {}
		let gridLength = 0
		for(let minute of [...Array(60 * 24).keys()]) grid[minute] = []

		let eventGrid = []
		for(let event of agendaEvents) {
			const startMinute = event.start.getHours() * 60 + event.start.getMinutes()
			const endMinute = event.end.getHours() * 60 + event.end.getMinutes()

			let len = null
			for(let l = 1; l <= gridLength; l++) {
				let valid = true
				for(let minute = startMinute; minute <= endMinute; minute++) {
					if(grid[minute].includes(l)) {
						valid = false
						break
					}
				}
				if(valid) {
					len = l
					break
				}
			}
			if(len === null) {
				gridLength++
				len = gridLength
			}

			for(let minute = startMinute; minute <= endMinute; minute++) {
				grid[minute].push(len)
				grid[minute].sort()
			}
			eventGrid.push({
				event: event,
				len: len,
				startMinute: startMinute,
				endMinute: endMinute
			})
		}

		let ret = {}
		const cellSize = cellWidth / gridLength
		for(let eventG of eventGrid) {
			let maxSize = gridLength + 1
			for(let minute = eventG.startMinute; minute <= eventG.endMinute; minute++) {
				let i = grid[minute].indexOf(eventG.len)
				if(grid[minute][i + 1] && grid[minute][i + 1] < maxSize) maxSize = grid[minute][i + 1]
			}

			const event = eventG.event
			let height = ((event.end - event.start) / 3600000 * 51) - 1
			if(height < 22) height = 22
			ret[event.id] = {
				height: height + 'px',
				width: (((maxSize - eventG.len) * cellSize) - 2) + 'px',
				top: (eventG.startMinute * cellHeight / 60) + 'px',
				offset: ((eventG.len - 1) * cellSize) + 'px',
				z: event.title
			}
		}

		return ret
	}
}
