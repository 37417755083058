import { Registry } from 'InterfaceBundle'

/**
 * Style
 */
import './styles/agenda.scss'

/**
 * Components
 */
import AgendaContainer from './components/AgendaContainer.vue'
import AgendaMonthView from './components/AgendaMonthView.vue'
import AgendaWeekView from './components/AgendaWeekView.vue'
import AgendaEventPreview from './components/AgendaEventPreview.vue'

Registry.components(AgendaContainer, AgendaMonthView, AgendaWeekView, AgendaEventPreview)

/**
 * Directives
 */

/**
 * Services
 */
import { AgendaGrid as AgendaGridService } from './services/AgendaGrid.js'

export const AgendaGrid = AgendaGridService