<template>
	<div>
		<ul class="calendarList">
			<li v-for="calendarUser in calendars" :key="calendarUser[0].ownerId" :class="{collapsed: isUserCollapsed(calendarUser[0].ownerId)}">
				<b @click="toggleCollapsedUser(calendarUser[0].ownerId)">
					<BlProfilePicture :showStatus="false" size="20" :id="calendarUser[0].ownerId" />
					<button class="bl-icon-button toggleHiddenButton" @click="toggleHiddenUser(calendarUser[0].ownerId); $event.stopPropagation();" :class="{active: isUserHidden(calendarUser[0].ownerId)}">{{ isUserHidden(calendarUser[0].ownerId) ? 'visibility_off' : 'visibility' }}</button>
					<span>{{ calendarUser[0].ownerId == userId ? $t('agenda.myCalendars') : calendarUser[0].ownerName }}</span>
					<button class="bl-icon-button">keyboard_arrow_up</button>
				</b>
				<ul>
					<li v-for="calendar in calendarUser" :key="calendar.id" @click="calendar.selected = !calendar.selected; updateSelected(calendar)">
						<input type="checkbox" class="bl-checkbox" :style="{'--bl-primary': isUserHidden(calendarUser[0].ownerId) ? 'var(--bl-legend)' : calendar.color}" v-model="calendar.selected" />
						<label>{{ calendar.name }}</label>
						<icon v-if="calendar.level == 0" v-bl-tooltip="$t('agendacalendarright.property.level.0')">event_busy</icon>
						<icon v-if="calendar.level == 1" v-bl-tooltip="$t('agendacalendarright.property.level.1')">calendar_today</icon>
						<icon v-if="calendar.level == 2" v-bl-tooltip="$t('agendacalendarright.property.level.2')">edit_calendar</icon>
					</li>
				</ul>
			</li>
		</ul>
		<BlButton :label="$t('agenda.manageCalendars.title')" icon="calendar_add_on" class="outlined dense" style="width: 100%; margin-top: 5px;" @click="manageCalendars()" />
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { Dialog, EventEmitter, ViewServices } from 'InterfaceBundle'

export default {
	name: 'AgendaContainerCalendars',
	emits: ['change'],
	inject: ['blAgendaOptions'],
	data() {
		return {
			calendars: [],
			userId: ViewServices.interfaceData.userPreferences.id
		}
	},
	created() {
		this.load()
	},
	methods: {
		load() {
			const ret = new EventEmitter()
			let req = {}
			req['context("calendars"):s.agenda.getUserCalendars(true)'] = {
				calendars: {
					'loop("line"):local.calendars': {
						id: null,
						selected: null,
						level: null,
						name: 'local.line.calendar.name',
						color: 'local.line.calendar.color',
						icon: 'local.line.calendar.icon',
						ownerId: 'local.line.ownerInContext().id',
						ownerName: 'local.line.ownerInContext() ~ ""'
					}
				}
			}
			Api.post('api/structure/', req).then(resp => {
				this.calendars = Object.groupBy(resp.calendars, l => l.ownerId)
				ret.emit()
			})
			return ret
		},
		updateSelected(calendar) {
			let req = {}
			req['context("result"):s.agendacalendaruser.toggleSelect(' + calendar.id + ')'] = {}
			Api.post('api/structure/', req).then(() => this.$emit('change'))
		},
		manageCalendars() {
			Dialog.custom({
				component: 'AgendaManageCalendars',
				required: true,
				closeButton: true
			}).then(() => {
				this.load().once(() => this.$emit('change'))
			})
		},
		isUserHidden(userId) {
			return this.blAgendaOptions().hiddenUsers.includes(userId)
		},
		toggleHiddenUser(userId) {
			if(this.isUserHidden(userId)) this.blAgendaOptions().hiddenUsers = this.blAgendaOptions().hiddenUsers.filter(uId => uId != userId)
			else this.blAgendaOptions().hiddenUsers = this.blAgendaOptions().hiddenUsers.concat([userId])
			let req = {}
			req['context("result"):s.agendasettings.toggleUser(' + userId + ', "hidden")'] = {}
			Api.post('api/structure/', req).then(() => this.$emit('change'))
		},
		isUserCollapsed(userId) {
			return this.blAgendaOptions().collapsedUsers.includes(userId)
		},
		toggleCollapsedUser(userId) {
			if(this.isUserCollapsed(userId)) this.blAgendaOptions().collapsedUsers = this.blAgendaOptions().collapsedUsers.filter(uId => uId != userId)
			else this.blAgendaOptions().collapsedUsers = this.blAgendaOptions().collapsedUsers.concat([userId])
			let req = {}
			req['context("result"):s.agendasettings.toggleUser(' + userId + ', "collapsed")'] = {}
			Api.post('api/structure/', req)
		}
	}
}
</script>

<style scoped lang="scss">
	ul.calendarList {
		margin: -35px 0 0 0;
		border-top: 1px solid var(--bl-border);
		padding: 7px 0 0 0;
		list-style: none;

		> li {
			padding: 0;

			> b {
				font-family: 'Product sans';
				font-weight: 600;
				display: block;
				display: flex;
				padding: 2px 3px 2px 5px;
				user-select: none;
				transition: background-color .2s;
				align-items: center;
				cursor: pointer;
				border-radius: var(--bl-border-radius);

				> div {
					margin-right: 5px;
				}

				> span {
					flex: 1;
				}

				> button.bl-icon-button {
					padding: 0;
					transition: transform .3s;
				}

				> button.bl-icon-button.toggleHiddenButton {
					padding: 0;
					transition: none;
					opacity: 0;
					margin-left: -26px;
					margin-right: 5px;
					font-size: 18px;
					background-color: var(--bl-surface);
					color: var(--bl-legend);
					transition: opacity .1s;
					width: 22px;
					height: 22px;
				}
			}

			> b:hover {
				background-color: var(--bl-background);
			}

			b:hover > button.bl-icon-button.toggleHiddenButton, button.bl-icon-button.toggleHiddenButton.active {
				opacity: .9;
			}

			> ul {
				list-style: none;
				padding: 8px 0;

				> li {
					border-radius: var(--bl-border-radius);
					display: flex;
					user-select: none;
					padding: 6px 6px;
					align-items: center;
					cursor: pointer;
					transition: background-color .2s;

					> input {
						margin-top: -1px;
						pointer-events: none;
					}

					> label {
						padding: 0 6px;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						flex: 1;
					}

					> icon {
						visibility: hidden;
						color: var(--bl-legend);
						font-size: 18px;
					}
				}

				> li:hover {
					background-color: var(--bl-background);

					> icon {
						visibility: visible;
					}
				}
			}
		}

		> li.collapsed {
			> b > button.bl-icon-button:not(.toggleHiddenButton) {
				transform: rotate(-180deg);
			}

			> ul {
				display: none;
			}
		}
	}
</style>